// React vendors
@import '~socicon/css/socicon.css';
@import '~@fortawesome/fontawesome-free/css/all.min.css';
@import '~line-awesome/dist/line-awesome/css/line-awesome.css';
@import '~prism-themes/themes/prism-shades-of-purple.css';
@import '~bootstrap-icons/font/bootstrap-icons.css';
@import '~animate.css/animate.css';
@import '~nouislider/dist/nouislider.css';

#root {
  display: contents;
}

/* Works for Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* for normal users chat*/

.hover-effect:hover {
  //opacity: 0.5;
  background-color: #f1faff;
}


.dropbtn {
  
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content span {
  color: black;
  padding: 12px 12px;
  text-decoration: none;
  display: block;
}

.dropdown-content span:hover {background-color: #ddd;}

.dropdown:hover .dropdown-content {display: block;}

/* for pagination*/
.datatable {
  width: 100%;
  margin: 0 auto;
  clear: both;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #d9d9d9;
  color:#6b6c72;
}

.datatablethead{
  height:60px;
}
.thformname{
  background-color: #47596d;
  // border-right:1px solid #fff
}
.thcategory{
  background-color: #758391;
}
.theadbtn{
  color:#ffffff;
  border:none;
  background:none;
  font-size:16px;
  font-weight:700;
  &:hover{
    cursor: pointer;
  }
}

.tablerow{
  &:nth-child(even) {
    background-color: #f5f5f7;  
  }
  
}
.tabledata{
  border: 1px solid #ddd;
  text-align: center;
  padding: 10px 20px;
  width:50%;
}

.search{
  margin:10px;
  display:block;
  float:right;
  color:#6b6c72;
}

.inputlabel{
  border: 1px solid #d6d6d6;
  padding: 8px;
  width: 150px;
  vertical-align: middle;
}


.pointer {cursor: pointer;}


.form-select-anonymous {
  --bs-form-select-bg-img: url(
"data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2378829d%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.775rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bs-gray-700);
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 16px 12px;
  border: 1px solid var(--bs-gray-300);
  border-radius: 0.475rem;
  box-shadow: false;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

.select2-container--bootstrap5 .select2-dropdown {
  border: 0;
  box-shadow:  "0px 0px 50px 0px rgba(82, 63, 105, 0.15)";
  border-radius: .475rem;
  padding: 1rem 0;
  background-color: #ffffff;
}


/* this css for sweet alert customization start*/
.custom-swal-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 9999;
}

.custom-swal-popup {
  background-color: #333; /* Customize the background color */
  color: #fff; /* Customize the text color */
  border-radius: 4px; /* Customize the border radius */
}

.custom-swal-icon {
  width: 20px; /* Customize the icon width */
  height: 20px; /* Customize the icon height */
  margin-right: 10px; /* Adjust the margin if needed */
}

/* this css for sweet alert customization end*/


.photo-grid {
  // display: grid;
  // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  // grid-gap: 10px;

  display: flex;
  flex-direction: row;
  gap: 10px;
} 

.photo-container {
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc;
}

.photo {
  width: 100%;
  height: auto;
}

.menu-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: #ff0000; /* Red background color (adjust as needed) */
  color: #fff; /* White text color */
  border: none;
  padding: 5px;
  cursor: pointer;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

// .input-group {
//   position: relative;
//   display: table;
//   border-collapse: separate;
// }

.suffix {
  border-radius: 4px 0px 0px 4px;
}

.suffix .input-group-addon {
  border-left: 0;
  border-radius: 0px 4px 4px 0px;
}

.legend-circle {
  display: inline-block;
  width: 10px; /* Adjust the size of the circle */
  height: 10px; /* Adjust the size of the circle */
  border-radius: 50%; /* Make it a circle */
  margin-right: 5px; /* Adjust the spacing between the circle and the text */
}

.product {
  padding: 5px;
  margin: 5px;
}
.time {
  font-weight: bold;
}
.rs {
  font-weight: bold;
}

/* user order model css start*/

.checkbox-round {
  width: 1em;
  height: 1em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #161515;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  margin-top: 4px;
}
.checkbox-round:checked {
//  background-color: #3E97FF;
border: 3px solid #3E97FF;
}
.checkbox-round:hover{
  // background-color: #3E97FF;
}
.checkmode:hover
{
  // color: #3E97FF;
}
.status:hover{
  // color: #3E97FF;
}
// .nav-item{
//   border-radius: 12px;
//   //padding: 4px;
// }
// .nav-item {
//   background-color: white;
// }
// .nav-item.active {
//   background-color: #bad6f6;
//   color: #3E97FF;
// }
.border{
  border: 1px solid black;
  border-radius: 12px;
}
.border{
  background-color: white;
}
.border.input{
  background-color: rgb(243, 248, 248);
}
/* styles.css */
.checkbox-label {
  color: black; /* Default font color */
  padding: 4px;
}
.checkbox-label.checked {
  color: #3E97FF; /* Font color when checked */
}
.checkbox-input {
  margin-left: 7px;
  margin-bottom: 7px;
}
/* styles.css */
.checkbox-container {
border: 1px solid black;
padding: 5px;
margin: 10px;
width: 200px;
display: flex;
justify-content: space-between;
border-radius: 10px;
}
.checkbox-container.checked {
border-color: #3E97FF; /* Change border color when checked */
}
.checkbox-label {
color: black; /* Default font color */
}
.checkbox-label.checked {
color: #3E97FF; /* Font color when checked */
}
.checkbox-input {
margin-left: 10px;
margin-bottom: 10px;
}


/* user order model css end*/

.rc-slider-track {
  background-color: #3E97FF !important;
}
.rc-slider-handle {
  border: 2px solid #3E97FF !important;
}
.rc-slider-handle:focus,
.rc-slider-handle:hover {
  border-color: #3E97FF !important;
  box-shadow: 0 0 5px #3E97FF !important;
}
/* custom-dates.css */
.DateRangePicker {
  width: 100%;
}
.DateInput {
  width: 100%;
}
.CalendarDay__selected,
.CalendarDay__selected_span,
.CalendarDay__hovered_span {
  background: #007BFF;
  border: 1px double #007BFF;
  color: white;
}
.CalendarDay__selected {
  background: #0056B3;
  border: 1px double #0056B3;
}


.select-option {
  position: relative; /* Enable positioning for the circle */
  padding-right: 20px; /* Adjust padding to accommodate circle size */
}

.select-option::after {
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  right: 5px; /* Adjust positioning for circle alignment */
  top: 50%;
  transform: translateY(-50%); /* Center the circle vertically */
}

.select-option.green::after {
  background-color: green;
}

.select-option.red::after {
  background-color: red;
}
// .table tbody tr:nth-child(odd) {
//   background-color: #F9FAFB; 
// }

// .table tbody tr:nth-child(even) {
//   background-color: #F9FAFB;
// }
// .table thead tr:nth-child(odd) {
//   background-color: #F9FAFB; 
// }

.online-now-container {
  border: 1px solid #e0e0e0; /* Light grey border */
  border-radius: 12px; /* Rounded corners */
  padding: 8px 12px; /* Padding around the content */
  display: flex;
  align-items: center;
  width: fit-content; /* Ensures the container is only as wide as its contents */
}

.label-text {
  margin-right: 8px; /* Space between the label and input */
  font-weight: 500; /* Slightly bold text */
  color: #333; /* Darker text color */
}


.online-now-container .form-check-input {
  transform: scale(0.8); 
  width: 20px;
  height: 20px; 
 
}
.border-primary {
  border: 1px solid #007bff;
}


.page-input-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33px;
  height: 30px;
  margin: 0 8px;  
  border-radius: 6px;
}

.page-input {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid #dbdfe9;
  border-radius: 5px;
  background: transparent;
  font-size: 13px;  
}


